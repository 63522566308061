.checkbox-style {
  position: absolute;
  width: 0;
  opacity: 0;
  visibility: hidden;

  + {
    label {
      text-align: left !important;
      margin: 5px 0;
      display: flex !important;
      cursor: pointer;

      span {
        flex: 1;
      }


      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid #c7d0d8;
        margin-right: 12px;
        display: inline-flex;
        position: relative;
        top: 3px;
      }
    }
  }
  &:checked{
      +{
          label{
              &:before{
                  border-color: $primary;
                  background-image: url("../../images/icons8-done.svg");
                  background-color: $primary;  
                  background-position: center;
                  background-size: 13px;
                  background-repeat: no-repeat;
                 }
          }
      }
  }
}
