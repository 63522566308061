h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
    color: $text-primary;
}

h1 {

    font-size: $font32 ;
}

h2 {
    font-size: $font18 ;
    text-indent: -1px;
    line-height: 1;
    font-weight: 600 ;
}

h3 {
    font-size: $font18;
    text-indent: -1px;
    line-height: 1;
    font-weight: 600;
}
a{
    text-decoration: none;
}
a:focus {
    outline: none;
    outline-offset: inherit;
}

button:focus {
    border: none;
    box-shadow: none;
    outline: none;
    border-color: transparent;
}

p {
    margin-bottom: 0;
}

ul li {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul {
    padding: 0;
    margin-left: 0;
}

.form-control:focus {
    box-shadow: none;
    outline: none;
    outline-offset: inherit;
}

.caption {
    text-align: center;
    max-width:780px;
    margin: 0 auto 50px;
    h1 {
        margin-bottom: 18px;
    }

    h2{
        margin-bottom: 18px;
    }

    p {
        font-size: $font17;
        color: $text-primary;
        margin: 0;
    }
}


