.btn{
    @include border-radius(100px);
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-size: $font26 !important;
    font-weight: 700 !important;
    min-width: 197px;
    height: 70px;
    border-radius: 100px !important;
    border: none;
   &.btn-primary{
        background-color: $primary;
        color: $white;

        &:hover{
            background-color: #084b94;
            color: $white;
        }
    }
    &:focus{
        box-shadow: none;
    }
}