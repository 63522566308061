/////////////////////////////////
//    Theme Primary Colors
//////////////////////////////////
$primary:#002853;
$text-primary:#000000;
$gray:#333;
$gray-dark:#222;
$white:#ffffff;
// COLOR OPACITY
/////////////////////////////////
//        FONT SIZES
//////////////////////////////////
$font96:96px;
$font82:82px;
$font60:60px;
$font56: 56px;
$font54:54px;
$font50: 50px;
$font48: 48px;
$font47: 47px;
$font44:44px;
$font42:42px;
$font40:40px;
$font38: 38px;
$font37: 37px;
$font36: 36px;
$font35: 35px;
$font34: 34px;
$font33: 33px;
$font32: 32px;
$font31: 31px;
$font30: 30px;
$font29: 29px;
$font28: 28px;
$font27: 27px;
$font26: 26px;
$font25: 25px;
$font24: 24px;
$font23: 23px;
$font22: 22px;
$font21: 21px;
$font20: 20px;
$font19: 19px;
$font18: 18px;
$font17: 17px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
$font13: 13px;
$font12: 12px;
$font11: 11px;
$font10: 10px;
$font8: 8px;