@mixin Roboto($font-weight: normal) {
    font-family: 'Roboto', sans-serif;
    font-weight: $font-weight;
}

@mixin border_radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}