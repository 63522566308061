@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './assets/scss/abstracts/mixin';
@import './assets/scss/abstracts/_variables';
@import './assets/scss/base/_reset';
@import './assets/scss/base/_typography';
@import './assets/scss/components/buttons';
@import './assets/scss/components/cards';
@import './assets/scss/components/form';
@import './assets/scss/responsive.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";
// ... or 
@import "~@ng-select/ng-select/themes/material.theme.css";

.ng-select.ng-select-single .ng-select-container,
.ng-input{
    height: 30px;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
    height: 30px;
}
.ng-select .ng-select-container .ng-value-container{
    padding: 0;
    border: none;
}

.ng-select{
    padding: 0;
}
.ng-select .ng-select-container{
    min-height: 30px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
    top: 0;
    bottom: 0;
}

.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper{
    bottom: 4px;
}
.ng-select .ng-select-container .ng-value-container{
    height: 30px;
    align-items: center;
    padding: 0 0;
}

.ng-select .ng-select-container{
    background: #c7d0d8;
    padding: 0 10px;
    border-radius: 0;
    border:1px solid #c7d0d8;
}
.ng-select.ng-select-opened>.ng-select-container{
    background: #c7d0d8;
    border:1px solid #c7d0d8;
}
.ng-select .ng-select-container:after{
    content: none;
}
.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper{
    bottom: 2px;
}

.btn.btn-default {
    background-color: #CCC;
    color: #002855;
}