.card-main {
  text-align: center;

  .img-title {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 277px;
    margin-bottom: 10px;

    a {
      height: 100%;
      width: 100%;
    }

    img {
      transition: all 0.3s;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .card-body {
    padding: 0;

    p {
      font-size: $font16;

      a {
        color: $text-primary;
        text-decoration: none;
        display: block;
      }
    }
  }
}
